import client from '@config/client'
import API_URL from '@config/services'

export const createLot = payload => {
  return client
    .post(API_URL.LOTS.POST_LOT(), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const updateLot = (lotId, payload) => {
  return client
    .patch(API_URL.LOTS.PATCH_LOT(lotId), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const deleteLot = lotId => {
  return client
    .delete(API_URL.LOTS.DELETE_LOT(lotId))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const getLots = (params = {}) => {
  return client
    .get(API_URL.LOTS.GET_LOTS(), { params })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const getLot = lotId => {
  return client
    .get(API_URL.LOTS.GET_LOT(lotId))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const getLotGroups = params => {
  return client
    .get(API_URL.LOTS.GET_LOT_GROUPS(), { params })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const createLotsGroup = payload => {
  return client
    .post(API_URL.LOTS.POST_LOT_GROUP(), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const updateLotsGroup = (lotId, payload) => {
  return client
    .patch(API_URL.LOTS.PATCH_LOT_GROUP(lotId), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const deleteLotGroup = lotId => {
  return client
    .delete(API_URL.LOTS.DELETE_LOT_GROUP(lotId))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onGetPrivateLots = id => {
  return client
    .get(`/lots/?page_size=999&operation=${id}&is_private=true`)
    .then(response => response?.data?.results || [])
    .catch(error => {
      return error.response
    })
}
