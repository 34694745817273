import React, { useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'

import { Grid, Button, TextField } from '@mui/material'

import UploadFile from '@components/common/Upload/UploadFile'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import { createFile, deleteFile, updateFile } from '@api/files'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'

export default function FileForm(props) {
  const {
    fileId,
    fileName,
    filesURL,
    selectedFile,
    operationId,
    contactId,
    leaseId,
    lotId,
    handleClose,
    setNewFileId
  } = props
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [logo, setLogo] = useState(null)
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const schema = yup.object().shape({
    name: yup.string().required(dictionary.required)
  })

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      name: fileName ?? ''
    }
  })

  const onSubmit = async values => {
    setLoading(true)

    const formData = new FormData()
    formData.append('name', values?.name)
    if (operationId) formData.append('operation', operationId)
    if (contactId) formData.append('contacts', [contactId])
    if (leaseId) formData.append('leases', [leaseId])
    if (lotId) formData.append('lots', [lotId])
    if (file) formData.append('file', file.fileData)

    let response
    if (fileId) {
      response = await updateFile(fileId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } else {
      response = await createFile(formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      setNewFileId(response?.data.id)
    }

    responseHandler({
      response,
      callback: async () => await mutate(filesURL),
      dictionary,
      msgSuccess: fileId ? dictionary.file_updated : dictionary.file_uploaded,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    handleClose?.()
  }

  const handleDelete = async id => {
    setLoading(true)
    const response = await deleteFile(id)
    responseHandler({
      response,
      callback: async () => await mutate(filesURL),
      dictionary,
      msgSuccess: dictionary.file_deleted,
      snackbar: enqueueSnackbar
    })
    setLoading(false)
    handleClose?.()
  }

  useEffect(() => {
    setLogo(selectedFile?.file)
  }, [selectedFile])

  return (
    <Grid width={'100%'}>
      <form data-cy={'form'} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  label={dictionary.name}
                  error={Boolean(errors.name)}
                  helperText={errors?.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadFile
              file={file}
              setFile={setFile}
              height="350px"
              isAllowed={true}
              logo={logo}
              setLogo={setLogo}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              disabled={loading}
              data-testid="Button-ac6c1c6e-1080-4975-8ad6-211d86c42c36"
            >
              {dictionary.submit}
            </Button>
          </Grid>
          {selectedFile && (
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                color="error"
                variant="outlined"
                onClick={() => handleDelete(selectedFile.id)}
                disabled={loading}
              >
                {dictionary.delete}
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Grid>
  )
}
