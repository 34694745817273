import { format } from 'date-fns'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

const downloadPDF = (data, title, team = '', orientation = 'landscape') => {
  if (data && data.length > 0) {
    const doc = new jsPDF(orientation) // orientation: 'portrait' or 'landscape'
    const pageSize = doc.internal.pageSize
    const pageHeight = pageSize.getHeight()
    const pageWidth = pageSize.getWidth()
    doc.text(pageWidth / 2 - doc.getTextWidth(team) / 2, 10, team)
    doc.text(15, 20, title)
    const footer = format(new Date(), 'dd-MM-yyyy HH:mm')
    doc.text(
      pageWidth / 2 - doc.getTextWidth(footer) / 2,
      pageHeight - 10,
      footer
    )
    const headers = Object.keys(data[0])

    autoTable(doc, {
      startY: 30,
      head: [headers],
      columns: headers.map(h => ({ header: h, dataKey: h })),
      didParseCell: data => {
        if (
          data.cell.raw === 'Net Surface' ||
          data.cell.raw === 'Rentable surface' ||
          data.cell.raw === 'Potential rent' ||
          data.cell.raw === 'Loyer potentiel' ||
          data.cell.raw === 'Actual rent' ||
          data.cell.raw === 'Loyer actuel' ||
          data.cell.raw === 'Surface area' ||
          data.cell.raw === 'Surface' ||
          data.cell.raw === 'Surface nette' ||
          data.cell.raw === 'Surface louable' ||
          data.cell.raw === 'Parking units' ||
          data.cell.raw === 'Unités de parking' ||
          data.cell.raw === 'Parking' ||
          data.cell.raw === 'Rent' ||
          data.cell.raw === 'Loyer' ||
          data.cell.raw === 'Occupancy' ||
          data.cell.raw === 'Occupancy rate' ||
          data.cell.raw === "Taux d'occupation" ||
          data.cell.raw === 'Occupation' ||
          data.cell.raw === 'Balance' ||
          data.cell.raw === 'Solde' ||
          data.cell.raw === 'Total' ||
          data.cell.raw === 'Total (AT)' ||
          data.cell.raw === 'Total (TTC)' ||
          (/^[0-9.-]*$/.test(data.cell.raw?.replace(/\s+/g, '')) &&
            data.cell.raw?.replace(/\s+/g, '').indexOf('-') ===
              data.cell.raw?.replace(/\s+/g, '').lastIndexOf('-'))
        ) {
          data.cell.styles.halign = 'right'
        }
      },
      body: data
    })
    doc.save(title)
  }
}

export default downloadPDF
