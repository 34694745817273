import React, { useEffect, useState } from 'react'

import { Controller } from 'react-hook-form'

import { useTranslation } from '@contexts/translation'

import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  Popover,
  TextField,
  Typography
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import makeStyles from '@mui/styles/makeStyles'
import { onSearchCompany } from '@api/contacts'

const useStyles = makeStyles(theme => ({
  suggestionList: {
    borderRadius: '8px',
    border: `1px solid #919eab52`,
    marginTop: 8,
    padding: 5,
    listStyle: 'none',
    '& li:last-child': {
      borderBottom: 'none !important'
    }
  },
  suggestionItem: { p: 5 }
}))

export default function CompanyNameAutocomplete(props) {
  const { companyName, control, errors, setValue, required = false } = props
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [suggestions, setSuggestions] = useState(null)
  const [error, setError] = useState()
  const [selectAnchorEl, setSelectAnchorEl] = useState(null)
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  // have to use another state for open popover because event.currentTarget is null after getting data in async function
  const [isOpenPopover, setIsOpenPopover] = useState(false)

  const handleOpenPopover = event => {
    setSelectAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => {
    setSelectAnchorEl(null)
    setIsOpenPopover(false)
  }

  useEffect(() => {
    if (errors && errors.company_name) {
      setError(errors.company_name)
    }
  }, [errors])

  const handleClickSearch = async () => {
    setIsLoadingSearch(true)
    const response = await onSearchCompany({ company_name: companyName })
    if (response) {
      if (response.status === 200) {
        const suggestionsList = response.data.result.resultats_nom_entreprise
        setSuggestions(suggestionsList)
        if (suggestionsList?.length > 0) setIsOpenPopover(true)
      }
    }
    setIsLoadingSearch(false)
  }

  const clearSuggestion = () => setSuggestions(null)

  const handleSelect =
    ({
      nom_entreprise,
      siren,
      domaine_activite,
      capital,
      siege,
      forme_juridique
    }) =>
    () => {
      setValue('company_name', nom_entreprise)
      setValue('siren', siren)
      setValue('activity_type', domaine_activite)
      if (forme_juridique) {
        if (forme_juridique === 'Entrepreneur individuel') {
          setValue('entity_type', 'Indi')
        }
        if (forme_juridique === 'SARL, société à responsabilité limitée') {
          setValue('entity_type', 'SARL')
        }
        if (forme_juridique === "SA à conseil d'administration (s.a.i.)") {
          setValue('entity_type', 'SA')
        }
        if (forme_juridique === 'SAS, société par actions simplifiée') {
          setValue('entity_type', 'SAS')
        }
        if (forme_juridique === 'Société en nom collectif') {
          setValue('entity_type', 'SNC')
        }
      }
      if (capital) setValue('capital', capital)
      if (siege)
        setValue(
          'headquarters_address',
          `${siege.adresse_ligne_1} ${siege.code_postal} ${siege.ville}`
        )

      if (siege)
        setValue(
          'address',
          `${siege.adresse_ligne_1} ${siege.code_postal} ${siege.ville}`
        )

      clearSuggestion()
      handleClosePopover()
    }

  const renderSuggestions = () =>
    suggestions?.map(suggestion => {
      const { nom_entreprise, siren } = suggestion

      return (
        <ListItemButton
          className={classes.suggestionItem}
          key={siren}
          onClick={handleSelect(suggestion)}
        >
          <strong>{nom_entreprise}</strong>&nbsp;<small>{siren}</small>
        </ListItemButton>
      )
    })

  return (
    <Grid item xs={12}>
      <Controller
        control={control}
        name="company_name"
        defaultValue=""
        render={({ field }) => (
          <>
            <TextField
              required={required}
              {...field}
              fullWidth
              variant="filled"
              label={dictionary.company_name}
              error={Boolean(error)}
              // helperText={error?.message || ''}
              InputProps={{
                endAdornment: (companyName ?? '').length >= 1 && (
                  <InputAdornment position="end">
                    <Typography
                      variant="subtitle1"
                      data-testid="Typography-e214d3c1-b71e-4877-acca-78daf0d94074"
                    >
                      {dictionary.insee_search} :
                    </Typography>

                    <IconButton
                      size="small"
                      onClick={async e => {
                        handleOpenPopover(e)
                        handleClickSearch()
                      }}
                      onMouseDown={e => e.preventDefault()}
                      data-testid="IconButton-ffd929d6-65ca-46dd-a4a7-f1d7e190d3bf"
                    >
                      {isLoadingSearch ? (
                        <CircularProgress size={25} />
                      ) : (
                        <SearchIcon sx={{ width: 25, height: 25 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Popover
              open={isOpenPopover}
              anchorEl={selectAnchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <List>{renderSuggestions()}</List>
            </Popover>

            {/* {suggestions && suggestions.length > 0 && (
              <ul className={classes.suggestionList}>{renderSuggestions()}</ul>
            )} */}

            {error && (
              <div
                style={{
                  color: '#EE0701',
                  marginTop: 4,
                  marginLeft: 8,
                  marginBottom: 0,
                  fontSize: 12
                }}
              >
                {error.message}
              </div>
            )}
          </>
        )}
      />
    </Grid>
  )
}
