import makeStyles from '@mui/styles/makeStyles'

import theme from '@styles/theme/theme'

export default makeStyles(() => ({
  root: { width: '100%' },
  dropZone: {
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('padding'),
    backgroundColor: theme.palette.elements.light,
    border: `3px dashed ${theme.palette.elements.main}`,
    '&:hover': {
      opacity: 0.72,
      cursor: 'pointer',
      border: `3px dashed ${theme.palette.text.light}`
    },
    '& input + img': {
      height: 'auto'
    },
    '& img[alt*=preview]': {
      width: '100%',
      height: 'auto',
      top: 0
    },
    '& > div': {
      marginLeft: 0,
      padding: '0 10px'
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      flexDirection: 'row'
    }
  },
  hasFile: {
    padding: '0'
  },
  isDragActive: {
    opacity: 0.72,
    border: `3px dashed ${theme.palette.secondary.main}`
  },
  isDragReject: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
    backgroundColor: theme.palette.error.light
  }
}))
