import client from '@config/client'
import API_URL from '@config/services'

export const updateContact = (id, payload) => {
  return client
    .patch(API_URL.CONTACTS.PATCH_CONTACTS_PK(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const updateContactWithFile = (id, payload, headers) => {
  return client
    .patch(API_URL.CONTACTS.PATCH_CONTACTS_PK(id), payload, headers)
    .then(response => response)
    .catch(error => error.response)
}

export const addContact = payload => {
  return client
    .post(API_URL.CONTACTS.POST_CONTACTS_LIST_PK(), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const addContactWithFile = (org_pk, payload, headers) => {
  return client
    .post(API_URL.CONTACTS.POST_CONTACTS_LIST_PK(), payload, headers)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteContact = id => {
  return client
    .delete(API_URL.CONTACTS.DELETE_CONTACT(id))
    .then(response => response)
    .catch(error => error.response)
}

/* Categories */

export const addContactCategory = (org_pk, payload) => {
  return client
    .post(API_URL.CONTACTS.POST_CONTACTS_CATEGORIES_LIST(org_pk), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const updateContactCategory = (id, payload) => {
  return client
    .patch(API_URL.CONTACTS.PATCH_CONTACTS_CATEGORY(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteContactCategory = id => {
  return client
    .delete(API_URL.CONTACTS.DELETE_CONTACTS_CATEGORY(id))
    .then(response => response)
    .catch(error => error.response)
}

/* Actions */

export const postContactAction = (org_pk, payload) => {
  return client
    .post(API_URL.CONTACTS.POST_CONTACTS_LIST_ACTION(org_pk), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const onSearchCompany = payload => {
  return client
    .post('/contacts/company_search/', {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}
