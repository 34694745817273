import ExportJsonExcel from 'js-export-excel'

const downloadXLS = (data, title, sheetName) => {
  const options = {
    fileName: title,
    datas: [
      {
        sheetData: data,
        sheetHeader: Object.keys(data[0])
      }
    ]
  }

  const excelExporter = new ExportJsonExcel(options)
  excelExporter.saveExcel()
}

export default downloadXLS
