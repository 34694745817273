import React, { useEffect, useState } from 'react'

import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Typography,
  Grid,
  Stack,
  Button
} from '@mui/material'

import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'
import PlaceHolder from '@components/common/PlaceHolder'
import { Add, Delete, Edit, InsertDriveFileOutlined } from '@mui/icons-material'
import Link from 'next/link'
import { format } from 'date-fns'
import CommonModal from '@components/common/Modal/CommonModal'
import FileForm from './FileForm'
import { useFetchData } from '@api/fetchData'
import ActionsPopover from '@components/common/ActionsPopover'
import DeleteModal from '@components/common/DeleteModal'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { deleteFile } from '@api/files'
import { updateLot } from '@api/lots'
import { useAuth } from '@contexts/auth'
import { useRouter } from 'next/router'

export default function Files(props) {
  const { filesURL, operationId, contactId, leaseId, lotId } = props
  const { dictionary, t } = useTranslation()
  const { user } = useAuth()
  const classesTable = useTableStyles()
  const { enqueueSnackbar } = useSnackbar()
  const router = useRouter()

  const currentURL = router.asPath

  const isTenant = user.role === 'tenant'

  const [files, setFiles] = useState([])
  const [filesIds, setFilesIds] = useState([])
  const [openFileForm, setOpenFileForm] = useState({
    open: false,
    fileId: null,
    fileName: null,
    file: null
  })
  const [openDeleteFile, setOpenDeleteFile] = useState({
    open: false,
    fileId: null
  })
  const [loading, setLoading] = useState(false)
  const { data: filesData } = useFetchData(
    leaseId || lotId || contactId ? filesURL : null
  )

  const [newFileId, setNewFileId] = useState()

  useEffect(() => {
    if (filesData) {
      setFiles(filesData.results)
      setFilesIds(filesData.results?.map(file => file?.id))
    }
  }, [filesData])

  let response
  useEffect(async () => {
    if (newFileId) {
      response = await updateLot(lotId, { files: [...filesIds, newFileId] })
      responseHandler({
        response,
        callback: async () => await mutate(filesURL),
        dictionary
      })
    }
  }, [newFileId])

  const actionsRows = file => [
    {
      id: 0,
      label: dictionary.edit,
      icon: <Edit color="secondary" />,
      handleClick: () => {
        setOpenFileForm({
          open: true,
          fileId: file.id,
          fileName: file.name,
          file: file
        })
      }
    },
    {
      id: 1,
      label: dictionary.delete,
      icon: <Delete color="error" />,
      handleClick: () => {
        setOpenDeleteFile({ open: true, fileId: file.id })
      }
    }
  ]

  const onConfirmDelete = async () => {
    setLoading(true)
    const response = await deleteFile(openDeleteFile.fileId)

    responseHandler({
      response,
      callback: async () => await mutate(filesURL),
      dictionary,
      msgSuccess: dictionary.field_deleted,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    setOpenDeleteFile({ open: false, fileId: null })
  }

  return (
    <>
      <Grid container spacing={3}>
        {!isTenant && !currentURL.includes('tenant/preview') && (
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <Button
              startIcon={<Add />}
              align="right"
              onClick={() =>
                setOpenFileForm({
                  open: true,
                  fileId: null,
                  fileName: null
                })
              }
              data-testid="Button-dca504bc-aab2-4eb3-99ef-d4b16e6c25c4"
            >
              {dictionary.add_file}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <TableContainer className={classesTable.container}>
            <Table>
              {!isTenant && (
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell>{dictionary.name}</TableCell>
                    <TableCell>{dictionary.created_at}</TableCell>
                    <TableCell>{dictionary.modified_at}</TableCell>
                    {!isTenant && !currentURL.includes('tenant/preview') && (
                      <TableCell />
                    )}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {!files?.length && (
                  <TableRow hover={false} className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <PlaceHolder
                        src="/Placeholders/Keysy/Finance autres.png"
                        wordingBordered={t(
                          'project_collaboration_files_no_files'
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )}

                {files?.length > 0 && (
                  <>
                    {files?.map(f => (
                      <TableRow
                        key={f.InsertDriveFileOutlined}
                        className={classesTable.row}
                      >
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Link href={f.file || ''}>
                              <a target="_blank">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <InsertDriveFileOutlined
                                    fontSize="small"
                                    color="secondary"
                                  />

                                  <Typography
                                    variant="subtitle2"
                                    color="secondary"
                                    data-testid="Typography-a6c17daf-a54c-4737-8ef0-622423f71f5b"
                                  >
                                    {f.name}
                                  </Typography>
                                </Stack>
                              </a>
                            </Link>
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ textTransform: 'capitalize' }}>
                          <Typography
                            variant="subtitle2"
                            data-testid="Typography-26b4bc28-5a06-4087-ad76-eb7bff39889b"
                          >
                            {f.created_at
                              ? format(
                                  new Date(f.created_at),
                                  'dd-MM-yyyy HH:mm'
                                )
                              : ''}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            data-testid="Typography-e87a6402-b3d0-4c64-ae0d-4da5a048b4cf"
                          >
                            {f.modified_at
                              ? format(
                                  new Date(f.modified_at),
                                  'dd-MM-yyyy HH:mm'
                                )
                              : ''}
                          </Typography>
                        </TableCell>
                        {!isTenant &&
                          !currentURL.includes('tenant/preview') && (
                            <TableCell
                              align="right"
                              className={classesTable.rowActions}
                            >
                              <ActionsPopover actions={actionsRows(f)} />
                            </TableCell>
                          )}
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <CommonModal
        open={openFileForm.open}
        title={openFileForm.fileId ? dictionary.edit_file : dictionary.add_file}
        onClose={() =>
          setOpenFileForm({
            open: false,
            fileId: null,
            fileName: null
          })
        }
        maxWidth={'sm'}
      >
        <FileForm
          fileId={openFileForm.fileId}
          fileName={openFileForm.fileName}
          filesURL={filesURL}
          selectedFile={openFileForm.file}
          operationId={operationId}
          contactId={contactId}
          leaseId={leaseId}
          lotId={lotId}
          handleClose={() =>
            setOpenFileForm({
              open: false,
              fileId: null,
              fileName: null
            })
          }
          newFileId={newFileId}
          setNewFileId={setNewFileId}
        />
      </CommonModal>

      <DeleteModal
        open={openDeleteFile.open}
        onClose={() => setOpenDeleteFile({ open: false, fileId: null })}
        onConfirm={onConfirmDelete}
        description={`${dictionary.confirm_to_delete} ${dictionary.file} ?`}
        loading={loading}
      />
    </>
  )
}
