import client from '@config/client'
import API_URL from '@config/services'

/* Folders */

export const createFolder = payload => {
  return client
    .post(API_URL.FILES.POST_FILES_FOLDER_LIST, { ...payload })
    .then(response => response)
    .catch(error => error.response)
}

export const updateFolder = (id, payload) => {
  return client
    .patch(API_URL.FILES.PATCH_FOLDER(id), {
      ...payload,
      parent: payload?.parent
    })
    .then(response => response)
    .catch(error => error.response)
}

export const deleteFolder = id => {
  return client
    .delete(API_URL.FILES.DELETE_FOLDER(id))
    .then(response => response)
    .catch(error => error.response)
}

/* Files */

export const createFile = (payload, headers) => {
  return client
    .post(API_URL.FILES.POST_FILES_LIST, payload, headers)
    .then(response => response)
    .catch(error => error.response)
}

export const getFile = id => {
  return client
    .get(API_URL.FILES.GET_FILE(id))
    .then(response => response)
    .catch(error => error.response)
}

export const updateFile = (id, payload, headers) => {
  return client
    .patch(API_URL.FILES.PATCH_FILE(id), payload, headers)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteFile = id => {
  return client
    .delete(API_URL.FILES.DELETE_FILE(id))
    .then(response => response)
    .catch(error => error.response)
}

export const downloadFile = (name, pk, org) => {
  return client
    .get(API_URL.FILES.DOWNLOAD_FILE + '?file_pk=' + pk + '&org=' + org, {
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${name}.pdf`)
      document.body.appendChild(link)
      link.click()
    })
    .catch(error => error.response)
}
